// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-reflexjs-gatsby-theme-core-src-page-template-js": () => import("./../../node_modules/@reflexjs/gatsby-theme-core/src/page-template.js" /* webpackChunkName: "component---node-modules-reflexjs-gatsby-theme-core-src-page-template-js" */),
  "component---node-modules-reflexjs-gatsby-theme-doc-src-doc-template-js": () => import("./../../node_modules/@reflexjs/gatsby-theme-doc/src/doc-template.js" /* webpackChunkName: "component---node-modules-reflexjs-gatsby-theme-doc-src-doc-template-js" */),
  "component---node-modules-reflexjs-gatsby-theme-post-src-post-template-js": () => import("./../../node_modules/@reflexjs/gatsby-theme-post/src/post-template.js" /* webpackChunkName: "component---node-modules-reflexjs-gatsby-theme-post-src-post-template-js" */),
  "component---node-modules-reflexjs-gatsby-theme-post-src-posts-template-js": () => import("./../../node_modules/@reflexjs/gatsby-theme-post/src/posts-template.js" /* webpackChunkName: "component---node-modules-reflexjs-gatsby-theme-post-src-posts-template-js" */),
  "component---node-modules-reflexjs-gatsby-theme-post-src-tag-template-js": () => import("./../../node_modules/@reflexjs/gatsby-theme-post/src/tag-template.js" /* webpackChunkName: "component---node-modules-reflexjs-gatsby-theme-post-src-tag-template-js" */),
  "component---node-modules-reflexjs-gatsby-theme-styleguide-src-styleguide-template-js": () => import("./../../node_modules/@reflexjs/gatsby-theme-styleguide/src/styleguide-template.js" /* webpackChunkName: "component---node-modules-reflexjs-gatsby-theme-styleguide-src-styleguide-template-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-reflexjs-gatsby-theme-base-pages-404-js": () => import("./../../src/@reflexjs/gatsby-theme-base/pages/404.js" /* webpackChunkName: "component---src-reflexjs-gatsby-theme-base-pages-404-js" */)
}

